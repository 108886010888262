/* eslint-disable require-jsdoc */
import * as React from 'react';
import { isDevelopmentMode } from '@mindhiveoy/foundation';
import _ from 'lodash';

export interface ErrorBoundaryProps {
  /**
   * Human readable name for error boundary. User this to help debugging where the exception happened.
   */
  name?: string;
  children?: any;

  /**
   * Error handler
   *
   * @param {Error}             error         Error object
   * @param {() => JSX.Element} renderDefault Default render function
   */
  onError?: (error: Error, renderDefault: () => JSX.Element) => JSX.Element;
}

interface StackLine {
  inProject?: boolean;
  text: string;
  filePath: string;
}
interface State {
  error?: Error;
  info?: React.ErrorInfo;
  componentStack?: StackLine[];
}
/**
 * Error boundary to restrict crash on ui to restricted area.
 */
export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  State
> {
  public state: State = {};

  /**
   * Prepare stack lines to emphasize files in active project.
   * @param {React.ErrorInfo} info
   * @return {StackLine[]}
   */
  private generateStackLines = (info: React.ErrorInfo): StackLine[] => {
    if (!info.componentStack) {
      return undefined;
    }

    const extractFilePath = (line: string): string => {
      const prefix = 'webpack-internal:///.';
      const index = line.indexOf(prefix);
      if (index < 0) {
        return null;
      }
      const filePath = line.substring(index + prefix.length);
      const endIndex = filePath.indexOf(')');
      return filePath.substring(0, endIndex);
    };

    const lines = info.componentStack.split('\n');

    return _.map(lines, (line) => {
      return {
        inProject: _.includes(line, '.ts'),
        filePath: process.env.SOURCE_CODE_PATH + extractFilePath(line),
        text: line,
      };
    });
  };
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({
      error,
      info,
      componentStack: this.generateStackLines(info),
    });
  }

  /**
   *
   * @return {JSX.Element} Default error component
   */
  private defaultRender = () => {
    // const {
    //   name,
    // } = this.props;

    const {
      componentStack,
      error,
    } = this.state;

    return (
      <div style={{
        padding: 16,
        backgroundColor: 'rgba(129,96,32, 0.6)',
      }}>
        { isDevelopmentMode() &&
          <iframe style={{
            float: 'right',
            marginLeft: 16,
            marginBottom: 16,
            width: 200,
          }}src="https://giphy.com/embed/d2ZcfODrNWlA5Gg0" width="480" height="360" frameBorder="0" className="giphy-embed" allowFullScreen></iframe> }
        <h1>
          Unexpected error condition
        </h1>
        <pre>{error.message}</pre>
        <h2>Stack trace</h2>
        <p>Use links to navigate to vscode.</p>
        <div>{ componentStack &&
          _.map(componentStack, (line, index) => {
            if (line.inProject) {
              return <div key={index}
                style={{
                  color: 'green' ,
                }}>
                <a href={`vscode://file/${line.filePath}`}>
                  <span style={{
                    whiteSpace: 'nowrap',
                  }}>{ line.text }</span>
                </a>
              </div>;
            }
            return <div key={index}
              style={{
                color: 'darkgray',
              }}>
              {line.text}
            </div>;
          }) }</div>
      </div>
    );
  };
  public render(): React.ReactNode {
    const {
      children,
      onError,
    } = this.props;
    const {
      error,
    } = this.state;

    if (error) {
      // You can render any custom fallback UI
      if (onError) {
        return onError(error, this.defaultRender);
      }
      return this.defaultRender();
    }
    return children;
  }
}
