
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
  KeyboardEventHandler, useCallback, useEffect, useMemo, useState
} from 'react';
import styled from '@emotion/styled';
import useForm, { FormValidationRules } from 'ts-react-form-validator';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { FormLayout } from './SignIn';
import { LayoutGroup } from 'framer-motion';
import { usePilotCode } from 'utils/hooks/usePilotCode';
import { useTranslation } from 'next-i18next';
import Alert from 'components/info/Alert';
import Center from 'components/common/Center';
import Form from 'components/forms/Form';
import FormElement from 'components/forms/FormElement';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

interface PilotCodeForm {
  pilotCode: string;
}

export const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const PilotCodeVerification: React.FunctionComponent = () => {
  const {
    t,
  } = useTranslation();

  const handleFormAreaClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const requiredText = useCallback(
    (): string | any => t('Required field'),
    [t,]
  );

  return (
    <FullScreen>
      <Center style={{
        height: '85%',
      }}>
        <FormLayout
          key='pilot-access-code-form'
          onClick={handleFormAreaClick}
        >
          <LayoutGroup>
            <PilotCodeVerificationForm />
          </LayoutGroup>
        </FormLayout>
      </Center>
    </FullScreen>
  );

  /**
   * Pilot access code form view
   *
   * @return {React.ReactElement} Pilot access code form
   */
  function PilotCodeVerificationForm() {
    const [error, setError,] = useState<string>(undefined);

    const {
      verifyPilotCode,
    } = usePilotCode();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const rules = useMemo((): FormValidationRules<PilotCodeForm> => {
      return {
        fields: {
          pilotCode: {
            required: true,
            requiredText,
          },
        },
        validateForm: (form): true | any => {
          const messages = {};
          return {
            ...form,
            messages,
          };
        },
      };
    }, []);

    const {
      form, events, messages, values: {
        pilotCode,
      },
    } = useForm<PilotCodeForm>(
      {
        pilotCode: '',
      },
      rules
    );

    /* Auto scroll to top */
    useEffect(() => {
      if (typeof window === 'undefined') {
        return;
      }
      window.scrollTo(0, 0);
    }, []);

    const handleVerifyPilotCodeClick = useCallback(async () => {
      if (!form.isFormValid) {
        return;
      }
      const isCodeValid = verifyPilotCode(pilotCode);
      if (!isCodeValid) setError(t('Invalid pilot code'));
    }, [form.isFormValid, pilotCode, verifyPilotCode,]);

    const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        handleVerifyPilotCodeClick();
      }
    }, [handleVerifyPilotCodeClick,]);

    return <Form<PilotCodeForm>
      widthPercentage={50}
      messages={messages}
      title={t('Pilot access code')}
      id="pilot-verification-form">
      <Grid container gap={2} marginTop={3}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6}>
            <FormElement field="pilotCode">
              <TextField
                label={t('Enter the pilot code')}
                value={pilotCode}
                id="pilotCode"
                {...events}
                onKeyUp={handleKeyUp} />
            </FormElement>

          </Grid>
          <Grid item xs={12} sm={2} marginTop={isMobile ? 1 : 0}>
            <Button
              onClick={handleVerifyPilotCodeClick}
              disabled={!form.isFormValid}
              variant='contained'
              color='secondary'>
              {t('Proceed to Block')}
            </Button>
          </Grid>
        </Grid>
        {error && <Alert visible={!!error}>{error}</Alert>}
      </Grid>
    </Form>;
  }
};

export default PilotCodeVerification;
