/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthError } from '@mindhiveoy/auth';
import { Media } from '@shared/schema/common';
import { useCallback, useContext, useEffect } from 'react';
import ErrorBoundary from 'components/common/ErrorBoundary';
import React, { useRef } from 'react';
// import SignIn from 'components/auth/SignIn';
import { usePilotCode } from 'utils/hooks/usePilotCode';
import { useRouter } from 'next/router';
import FullScreenLoader from 'components/FullScreenLoader';
import PilotCodeVerification from 'components/auth/PilotCodeVerification';
import styled from '@emotion/styled';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentArea = styled.div(({
  theme,
}) => `
  margin: 0;
  margin-top: ${theme.constants.headerHeight};
  height: 100vh;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
`);

const ScrollAreaRefContext = React.createContext<React.RefObject<HTMLDivElement>>(null);

export const useScrollAreaRef = () => {
  return useContext(ScrollAreaRefContext);
};

export interface AppProps {
  media?: Media;
  blurBackground?: boolean;
}

const App = ({
  children,
}: React.PropsWithChildren<AppProps>): JSX.Element => {
  const scrollAreaRef = useRef(null);

  const {
    pilotCodeEntered,
    isLoading,
  } = usePilotCode();
  const router = useRouter();

  const handleError = useCallback((error: Error, renderDefault) => {
    if (error instanceof AuthError && error.errorCode === 'auth_permission_denied') {
      // return <SignIn/>;
      return <div></div>;
    }
    return renderDefault();
  }, []);

  useEffect(() => {
    if (scrollAreaRef && scrollAreaRef?.current) {
      scrollAreaRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [router,]);

  return <AppContainer ref={scrollAreaRef}>
    <ScrollAreaRefContext.Provider value={scrollAreaRef}>
      {/* { media && // TODO remove borderRadius from backgrounds
        <MediaContainer fullScreen media={media} blur={blurBackground}/> } */}
      <ContentArea>
        <ErrorBoundary name="app" onError={handleError}>
          {isLoading ? <FullScreenLoader /> : pilotCodeEntered ? children : <PilotCodeVerification />}
        </ErrorBoundary>
      </ContentArea>
    </ScrollAreaRefContext.Provider>
  </AppContainer>;
};

export default App;
