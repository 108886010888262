/* eslint-disable sonarjs/no-identical-functions */
import { CSSProperties, useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import theme from 'theme';

const FullScreenLoader = (): JSX.Element => {
  const containerStyle = useMemo(() => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      width: '100vw',
      height: '100%',
    };
  }, []);

  const progressStyle = useMemo(() => {
    return {
      color: theme.palette.secondary.main,
    };
  }, []);

  return <div style={containerStyle}>
    <CircularProgress
      size='4rem'
      style={progressStyle} />
  </div>;
};

export const SmallLoader = () => {
  const containerStyle = useMemo((): CSSProperties => {
    return {
      width: '100%',
      textAlign: 'center',
    };
  }, []);

  const progressStyle = useMemo(() => {
    return {
      color: theme.palette.secondary.main,
    };
  }, []);

  return <div style={containerStyle}>
    <CircularProgress
      size='4rem'
      style={progressStyle} /></div>;
};

export default FullScreenLoader;
